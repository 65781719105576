.streamCard {
    overflow: hidden;
    border-radius: 20px !important;
    width: 350px;
    height: 100%;
    transform: scale(0.95);
    box-shadow:
            0px 2.8px 2.2px rgba(0, 0, 0, 0.045),
            0px 6.7px 5.3px rgba(0, 0, 0, 0.065),
            0px 12.5px 10px rgba(0, 0, 0, 0.08),
            0px 22.3px 17.9px rgba(0, 0, 0, 0.095),
            0px 41.8px 33.4px rgba(0, 0, 0, 0.115),
            0px 100px 80px rgba(0, 0, 0, 0.16)
!important;

}
.streamCardFirst {
    position: relative;
    overflow: hidden;
    border-radius: 20px !important;
    width: 350px;
    height: 100%;
    box-shadow:
            0px 2.8px 2.2px rgba(0, 0, 0, 0.045),
            0px 6.7px 5.3px rgba(0, 0, 0, 0.065),
            0px 12.5px 10px rgba(0, 0, 0, 0.08),
            0px 22.3px 17.9px rgba(0, 0, 0, 0.095),
            0px 41.8px 33.4px rgba(0, 0, 0, 0.115),
            0px 100px 80px rgba(0, 0, 0, 0.16)
    !important;

}
.streamCardFirst:after {
    content:'';
    top:0;
    width:100%;
    height:100%;
    position: absolute;
    z-index:99;
    animation: slide 5s infinite;
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(.57,.04,0,.88);
    opacity: 0.45;

    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255, 239, 224, 0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%, rgba(255, 239, 224, 0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255, 239, 224, 0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255, 239, 224, 0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255, 239, 224, 0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255, 239, 224, 0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}
@keyframes slide {
    0% {transform:translateX(-200%) rotate(12deg) scale(2);}
    100% {transform:translateX(200%) rotate(12deg);}
}


.gameImageContainer {
    position: relative;
    height: 383px;
}
.text {
    position: relative;
    z-index: 10;
}
.gameImage {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    border-radius: 12px;
}
.gameImageShadowFirst {
    filter: blur(25px) saturate(4);/* this is the magic part */
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    transform: scale(5);
    width: 100%;
    z-index: 1;
}
.gameImageShadow {
    filter: blur(25px) saturate(0.5);/* this is the magic part */
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    transform: scale(5);
    width: 100%;
    z-index: 1;
}
