.wrapper {
    z-index: 20;
    position: relative;
    height: 100vh;
}
.chatWrapper {
    height: 100%;
}
.chatFrame {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border: none;
}

