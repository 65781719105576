.pageContainer {
    height: 100vh;
}
.listItem {
    cursor: pointer;
}
.listItem:hover {
    background-color: #2d3331;
}
.activeListItem {
    background-color: #0f1110;
    border-left: 4px solid #f08026;
}
.addButtonText {
    font-size: 1.7rem;
}
.arrowWrapper {
    position: relative;
    width: 0px;
}
.arrow {
    right: 24px;
    box-shadow: 0px 0px 0px 7px #0f1110 !important;
    z-index: 99;
    position: absolute;
}
.activePaper {
    box-shadow: 0px 0px 0px 2px #f08026 !important;
}
.slidePaper {
    cursor: pointer;
}
.formButton {
    height: 48px !important;
}
.deleteButton {
    position: absolute;
    top: 0;
    right: 0;
}
.divider {
    width: 2px;
    background-color: #ffffff;
}
.check {
    color: #f08026;
    position: absolute;
    font-size: 2rem;
    display: flex;
}
