.overlayContainer {
    background: transparent;
    position: absolute;
    z-index: 99;
}
.logoWrapper {
    position: absolute;
    top: 0;
    left: 0;
}
.countdownWrapper {
    position: absolute;
    top: 0;
    right: 0;
}
.timeBar {
    background: #f08026;
    height: 15px;
    position: fixed;
    bottom: 0;
    left: 0;
}
.chatWrapper {
    height: 100%;
    width: 30%;
    margin: auto;
}
.chatFrame {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border: none;
}
